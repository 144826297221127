<template>
  <div class="flex flex-col items-center justify-start group">
    <div class="relative w-full">


      <div class="group">
        <div class="rounded-lg relative">
          <div class="bg-black/10 backdrop-blur-lg font-bold rounded-md text-black absolute top-8 z-20 text-xs py-1 px-2 m-2" v-if="selectedVariant?.isNew">
            {{ $t('app.new') }}
          </div>

          <DiscountPercent :selected-variant="selectedVariant" v-if="!showRateNextToPrice"/>

          <NuxtLinkLocale :to="!quickViewIsActive && selectedVariant?.url ? selectedVariant.url : '#'" class="block z-10" @click.prevent="quickViewIsActive ? openQuickModal(selectedVariant?.url) : null" @mouseenter="hoverImage=true" @mouseleave="hoverImage=false">
            <div class="flex absolute z-20 justify-center text-center items-center w-full h-full" v-if="!selectedVariant?.inStock">
              <span class="product-out-of-stock"><StockOutText /></span>
            </div>

            <div
              class="flex absolute z-20 justify-center text-center items-end w-full h-full"
              v-if="selectedVariant?.isFreeShipping"
            >
              <span class="p-1.5 text-sm bg-lightgray-50 w-full rounded-b-lg flex items-center justify-center">
                <BoltIcon class="h-5 w-5 mr-2 fill-primary" />
                <span>{{ $t('app.free-shipping') }}</span>
              </span>
            </div>

            <NuxtImg
              v-if="sourceImage && !isSlider"
              width="600"
              height="900"
              fit="outside"
              loading="lazy"
              :placeholder="[50, 50, 93, 3]"
              :quality="90"
              :alt="selectedVariant?.title"
              :class="{ 'product-img-out-of-stock': !selectedVariant?.inStock }"
              class="z-1 object-contain"
              :src="sourceImage"
            />

            <NuxtImg
              v-else-if="sourceImage && isSlider"
              width="600"
              height="900"
              fit="outside"
              loading="lazy"
              :placeholder="[50, 50, 93, 3]"
              :quality="90"
              :alt="selectedVariant?.title"
              :class="{ 'product-img-out-of-stock': !selectedVariant?.inStock }"
              class="z-1 object-contain aspect-square"
              :src="image"
            />
            <NuxtImg
              v-else
              fit="outside"
              alt=""
              class="z-1 object-contain"
              width="368"
              height="552"
              src="/images/no-image.png"
            />
          </NuxtLinkLocale>

          <!-- Ürün Seçenekleri -->
          <SectionProductCardGroupOptions :product="product" />

        </div>

        <div class="flex flex-col text-base text-primary mt-2" v-if="!isSlider">
          <div class="mt-2 line-clamp-2">
            {{ selectedVariant?.title }}
          </div>

          <!-- Fiyat Bilgisi -->
          <!-- Toptan Fiyat Görünümü -->
          <div class="card-wholesale-price-wrapper" v-if="data?.customer_type === 1 && (typeof hidePricesForGuest === 'undefined' || !hidePricesForGuest)">
            <PriceUpdateModeDescriptionForB2B>
              <!-- Üstü çizili fiyatı göster -->
              <div class="card-campaign-price" v-if="showListPrice">
                <div class="card-sale-price">
                  {{ formatPrice(selectedVariant?.primaryStockData?.wholesaleListPrice, selectedVariant?.primaryStockData?.currency) }}
                </div>
                <div class="card-sale-price" v-if="selectedVariant?.primaryStockData?.discountPrice > selectedVariant?.primaryStockData?.wholesalePrice && showListPriceAndCampaignTogether === 1">
                  {{ formatPrice(selectedVariant?.primaryStockData?.discountPrice, selectedVariant?.primaryStockData?.currency) }}
                </div>
              </div>

              <!-- Toptan fiyat bilgisini göster - Airlife toptan fiyat bilgisini sadece detayda gösteriyor -->
              <div v-else class="card-campaign-price" :class="discountRateIsExits(selectedVariant, isWholesaleUser) ? 'text-campaign-color' : 'text-price-color'">
                <span v-if="discountRateIsExits(variant, isWholesaleUser) && withInCartTextForCampaigns">Sepette </span>
                {{ formatPrice(getPriceListPrice('Y'), selectedVariant?.primaryStockData?.currency) }}

                <!-- KDV Bilgilendirmesi ve Dahil / Hariç Durumu -->
                <SectionProductVatRateInformation :selected-variant="selectedVariant" />
              </div>
            </PriceUpdateModeDescriptionForB2B>
          </div>

          <!-- Kampanyalı Fiyat Görünümü -->
          <div class="card-price-wrapper" v-else-if="selectedVariant?.primaryStockData?.discountPrice > 0 && selectedVariant?.primaryStockData?.discountPrice !== selectedVariant?.primaryStockData?.price && (isWholesaleUser || accessType !== 'b2b') && !hidePricesForGuest">
            <DiscountPercent :selected-variant="selectedVariant" class="!relative !m-0" v-if="showRateNextToPrice"/>

            <div class="campaign-price-wrapper" v-if="showListPrice && showListPriceAndCampaignTogether === 1">
              <div class="card-list-price">
                {{ formatPrice(selectedVariant?.primaryStockData?.listPrice, selectedVariant?.primaryStockData?.currency) }}
              </div>
              <div class="card-sale-price with-campaign">
                {{ formatPrice(selectedVariant?.primaryStockData?.price, selectedVariant?.primaryStockData?.currency) }}
              </div>
            </div>

            <div class="card-list-price" v-else>
              {{ formatPrice(selectedVariant?.primaryStockData?.price, selectedVariant?.primaryStockData?.currency) }}
            </div>

            <div class="card-campaign-price" :class="discountRateIsExits(selectedVariant, isWholesaleUser) ? 'text-campaign-color' : 'text-price-color'">
              <span v-if="discountRateIsExits(selectedVariant, isWholesaleUser) && withInCartTextForCampaigns">{{ $t('listing.campaign-in-cart-text', [formatPrice(selectedVariant?.primaryStockData?.wholesalePrice, selectedVariant?.primaryStockData?.currency)]) }} </span>
              <span v-else>{{formatPrice(selectedVariant?.primaryStockData?.wholesalePrice, selectedVariant?.primaryStockData?.currency)}}</span>
              <span class="card-unit-label" v-if="showProductUnit && selectedVariant?.unitName"> / {{selectedVariant?.unitName}}</span>
            </div>

            <!-- KDV Bilgilendirmesi ve Dahil / Hariç Durumu -->
            <SectionProductVatRateInformation :selected-variant="selectedVariant" />
          </div>

          <!-- Kampanyasız Fiyat Görünümü -->
          <div v-else-if="(isWholesaleUser || accessType !== 'b2b') && !hidePricesForGuest">
            <div class="card-price-wrapper">
              <DiscountPercent :selected-variant="selectedVariant" class="!relative !m-0" v-if="showRateNextToPrice"/>

              <div class="card-list-price" v-if="showListPrice && selectedVariant?.primaryStockData?.listPrice">
                {{ formatPrice(selectedVariant?.primaryStockData?.listPrice, selectedVariant?.primaryStockData?.currency) }}
              </div>

              <div class="card-sale-price">
                {{ formatPrice(selectedVariant?.primaryStockData?.price, selectedVariant?.primaryStockData?.currency) }}
                <span class="card-unit-label" v-if="showProductUnit && selectedVariant?.unitName"> / {{selectedVariant?.unitName}}</span>
              </div>
            </div>

            <!-- KDV Bilgilendirmesi ve Dahil / Hariç Durumu -->
            <SectionProductVatRateInformation :selected-variant="selectedVariant" />
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { HeartIcon, BoltIcon } from "@heroicons/vue/24/outline";
import { HeartIcon as HeartFilledIcon } from "@heroicons/vue/24/solid";
import TriangleIcon from "assets/icons/triangle.svg";
import AddToCartIcon from "assets/icons/add-to-cart.svg";

import { collect } from "collect.js";
import DiscountPercent from "~/components/DiscountPercent.vue";

const props = defineProps(["product", "selectedWarehouse", "isSlider", "image"]);
const emit = defineEmits(['setImage', 'setHoverImage'])

const storeConfig = useStoreConfig()
const { accessType, baseConfig, showRateNextToPrice, withInCartTextForCampaigns, quickViewIsActive } = storeToRefs(storeConfig)
const {status, data} = useAuth()

const {
  showListPriceAndCampaignTogether,
  showProductUnit,
  showListPrice,
  hidePriceForSoldOut
} = useListing()

const { hidePricesForGuest } = useHelper();

const {openQuickModal} = useQuickProductView()

// productSelectedVariant
const selectedVariant = useState(`selectedVariant::${props.product.productId}::${props.product.productGroupValue}`);

// Ön tanımlı ürün görseli
const sourceImage = useState(`selectedVariantImage::${props.product.productId}::${props.product.productGroupValue}`);

const hoverImage = ref(`selectedVariantImageIsHover::${props.product.productId}::${props.product.productGroupValue}`);

const {selectedWarehouse} = useListing()

const variants = await collect(props.product.variants);

const { addToBasket, addCartLoading } = await useBasket();
const { isWholesaleUser, authModalIsVisible } = await useCustomer();
const { addWishlist } = useWishlist();

const getPriceListPrice = (groupCode) => {
  if(typeof selectedVariant.value?.primaryStockData?.priceGroups === 'undefined' || selectedVariant.value?.primaryStockData?.priceGroups?.length === 0 || !groupCode) return null;
  return collect(selectedVariant.value?.primaryStockData?.priceGroups).where('group_code', groupCode).pluck('discounted_wholesale_price').first()
}

</script>

<style>
.card-button {
  @apply w-full flex text-sm bg-gray-100 items-center justify-center rounded-lg font-bold py-2 px-2 text-primary fill-primary hover:text-white hover:fill-white;
}
</style>
